import axios from "axios";
import { catchError } from "~/utils/catchError";

export const updateSelectedResource = async (
  service: "ppc" | "ppl",
  resourceId: number,
) => {
  try {
    const requestBody = {
      service,
      resourceId,
    };

    await axios.post("/api/selectedResource", requestBody);
  } catch (error: unknown) {
    catchError(error, { reason: "Failed to set selected resource" });
  }
};
